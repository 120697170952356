import { graphql } from "gatsby"
import React from "react"
import Layout from "../components/global/layout"
import SearchEngineOptimizaion from "../components/global/SearchEngineOptimizaion"

import PostGrid from "../components/global/PostGrid"
import BlockContent from "@sanity/block-content-to-react"
import { serializers } from "@sanity/block-content-to-react/lib/targets/dom"
import { Divider } from "@chakra-ui/layout"

export default function category({ data }) {
  const posts =
    data.allSanityRecipes.nodes.length !== 0
      ? data.allSanityRecipes.nodes
      : data.allSanityPost.nodes

  const { tags, _rawBody } = data.sanityCategories

  return (
    <Layout>
      <SearchEngineOptimizaion
        title={
          tags !== ""
            ? "Opskrifter på " + tags.toLowerCase()
            : "Gatrologik | Mad med mening"
        }
        description={tags !== "" ? tags : "Gatrologik | Mad med mening"}
      />

      <div className="titleWrapper">
        <h1>{tags}</h1>
        <div className="spanLine"></div>
      </div>
      <div className="categoryDescriptionWrapper">
        {_rawBody && (
          <BlockContent
            blocks={_rawBody}
            projectId="2aoj8j2d"
            dataset="gastrologik"
            serializers={serializers}
          />
        )}
      </div>
      <Divider mb="70px" />
      <PostGrid posts={posts} />
    </Layout>
  )
}

export const Query = graphql`
  query ($Slug: String) {
    allSanityRecipes(
      filter: { tags: { elemMatch: { value: { eq: $Slug } } } }
      sort: { publishedAt: DESC }
    ) {
      nodes {
        title
        image: recipeImage {
          verticalImage {
            asset {
              gatsbyImageData(
                width: 280
                height: 366

                backgroundColor: "#f9f9f9"
                placeholder: NONE
              )
            }
          }
        }
        slug {
          current
        }
      }
    }
    allSanityPost(
      filter: { tags: { elemMatch: { value: { eq: $Slug } } } }
      sort: { publishedAt: DESC }
    ) {
      nodes {
        title
        image: mainImage {
          asset {
            gatsbyImageData(
              width: 280
              height: 366

              backgroundColor: "#f9f9f9"
              placeholder: NONE
            )
          }
        }
        slug {
          current
        }
      }
    }

    sanityCategories(slug: { current: { eq: $Slug } }) {
      tags
      slug {
        current
      }
      _rawBody
    }
  }
`
